import { gql } from '@apollo/client';
import { DOCUMENT_FRAGMENT } from 'graphql/fragments/documents';

export const SDG_FRAGMENT = gql`
  fragment sdgValues on CmsSustainableDevGoal {
    title
    description
    number
    color
    iconUrl
    id
  }
`;

export const IMPACT_CATALOG_ITEM_FRAGMENT = gql`
  fragment impactCatalogItemValues on AdminImpactCatalogItem {
    id
    category {
      id
      type
    }
    categoryType @client
    categoryId @client
    types
    links
    thirdParties
    iconUrl
    title {
      language
      value
    }
    definition {
      language
      value
    }
    sustainableDevGoals {
      number
      id
      title {
        language
        value
      }
      color
      iconUrl
      description {
        language
        value
      }
    }
    sustainableDevGoalsIds @client
    acceptanceCriteria {
      description {
        language
        value
      }
    }
    docRequirements {
      description {
        language
        value
      }
    }
  }
`;

const IMPACT_CATALOG_SMALL_FRAGMENT = gql`
  fragment ImpactCatalogItemSmall on CmsImpactCatalogItem {
    category {
      title
      type
      id
    }
    title
    definition
    acceptanceCriteria {
      description
    }
    docRequirements {
      description
    }

    sustainableDevGoals {
      ...sdgValues
    }
  }
`;

export const IMPACT_CLAIM_FRAGMENT = gql`
  fragment impactClaimValues on ImpactClaim {
    id
    createdTimestamp
    modifiedTimestamp
    attachmentCount
    status
    attachments {
      edges {
        node {
          ...documentsValues
        }
      }
    }
    impactCatalogItem {
      ...ImpactCatalogItemSmall
    }
    component {
      id
      title
    }
    product {
      id
      title
      publicUrl
    }
    createdBy {
      id
      name
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${SDG_FRAGMENT}
  ${IMPACT_CATALOG_SMALL_FRAGMENT}
`;

export const IMPACT_CLAIM_LOG = gql`
  fragment impactClaimLog on ImpactClaimLog {
    comment
    timestamp
    action
    user {
      email
      firstName
      lastName
    }
  }
`;
