import React, { FC, Fragment, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TableCell } from '@mui/material';
import get from 'lodash/get';
import {
  IRawMaterialLogAction,
  IRawMaterialMultiLang
} from 'types/rawMaterials.types';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault
} from 'use-query-params';
import { getEnglishValue } from 'utils/translations';
import { SearchField } from 'components/Companies/CompaniesPage/styles';
import Table, { HeadCell } from 'components/Table/Table';
import useRawMaterials from './hooks/useRawMaterials';

const headCells: HeadCell<IRawMaterialMultiLang<string | undefined>>[] = [
  {
    id: 'title',
    label: 'Raw material'
  },
  {
    id: 'latestLogAction',
    label: 'Status'
  }
];

interface IManageRawMaterialsPageProps {
  activeRawMaterialStatuses: IRawMaterialLogAction[];
}

const ManageRawMaterialsPage: FC<IManageRawMaterialsPageProps> = ({
  activeRawMaterialStatuses
}) => {
  const [query, setQuery] = useQueryParams({
    search: withDefault(StringParam, ''),
    pageSize: withDefault(NumberParam, 20),
    page: withDefault(NumberParam, 0)
  });
  const { page, pageSize, search } = query;

  const navigate = useNavigate();
  const { handleRawMaterialClick, rawMaterials } = useRawMaterials({
    activeRawMaterialStatuses
  });

  const filteredRawMaterials = useMemo(
    () =>
      rawMaterials
        .map(value => ({
          ...value,
          title: getEnglishValue(value.title)
        }))
        .filter(({ title }) =>
          title?.toLowerCase().includes(search.toLowerCase())
        ),
    [rawMaterials, search]
  );

  return (
    <Box>
      {/* Wait until backend is ready */}
      <Box display="flex" justifyContent="space-between" mb={2}>
        <SearchField
          id="outlined-basic"
          placeholder="Search..."
          size="small"
          style={{ marginBottom: 0 }}
          value={search}
          variant="outlined"
          onChange={event => {
            setQuery({ search: event.target.value, page: 0 });
          }}
        />

        <Button
          variant="contained"
          onClick={() => navigate(`/raw-materials/new`)}
        >
          Add new
        </Button>
      </Box>

      <Table<IRawMaterialMultiLang<string | undefined>>
        defaultRowsPerPage={pageSize}
        defaultSorting="title"
        headCells={headCells}
        page={page}
        rows={filteredRawMaterials}
        onPageChange={page => setQuery({ page })}
        onPageSizeChange={pageSize => setQuery({ pageSize })}
        onRowClick={handleRawMaterialClick}
      >
        {row => (
          <Fragment>
            {headCells.map(({ id }) => {
              return <TableCell key={id}>{get(row, id)}</TableCell>;
            })}
          </Fragment>
        )}
      </Table>
    </Box>
  );
};

export default ManageRawMaterialsPage;
