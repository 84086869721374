import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { InfoOutline } from 'styled-icons/evaicons-outline';
import { Check, XLg } from 'styles/icons';
import { ImpactClaim } from 'types/types.types';
import { formatDateTime } from 'utils/dates';
import ClaimHistory from 'components/ImpactClaims/ActiveSubmission/ClaimHistory/ClaimHistory';
import useSingleSubmission from 'components/ImpactClaims/hooks/useSingleSubmission';
import RejectSubmissionDialog from 'components/ImpactClaims/RejectSubmissionDialog/RejectSubmissionDialog';
import DocumentContainer from 'components/shared/DocumentContainer/DocumentContainer';
import LabelWithValue from 'components/shared/LabelWithValue/LabelWithValue';
import { Container } from './styles';

const SubmissionDetails: React.FC = () => {
  const { submissionId } = useParams();
  const { claimSubmission, handleApprove, handleReject, impactClaimHistory } =
    useSingleSubmission({
      id: submissionId
    });
  const [rejectModalActive, setRejectModalActive] = useState<boolean>(false);
  const toggleRejectModal = () =>
    setRejectModalActive(currentValue => !currentValue);
  const onRejectSubmit = (props: { reason: string }) => {
    toggleRejectModal();
    handleReject(props);
  };

  const getImpactClaimLevel = (claimSubmission: ImpactClaim): string => {
    if (claimSubmission?.createdBy) return 'PARTNER';
    else if (claimSubmission?.product) return 'PRODUCT';
    else if (claimSubmission?.component) return 'COMPONENT';
    else return 'COMPANY';
  };

  const getRelationTitle = (
    claimSubmission: ImpactClaim
  ): JSX.Element | string => {
    if (claimSubmission?.createdBy) {
      return claimSubmission.createdBy.name;
    } else if (claimSubmission?.component) {
      return claimSubmission.component.title;
    } else if (claimSubmission?.product) {
      const { product } = claimSubmission;
      return (
        <Link
          color="secondary"
          href={`${product.publicUrl}?preview=1`}
          mr={2}
          target="blank"
          variant="body2"
        >
          {product.title}
        </Link>
      );
    } else {
      return '';
    }
  };

  if (!claimSubmission) return null;

  return (
    <Fragment>
      <RejectSubmissionDialog
        open={rejectModalActive}
        onCancel={toggleRejectModal}
        onSubmit={onRejectSubmit}
      />
      <Container height="100%" paddingX={3} paddingY={2}>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <h2>Claim Submission</h2>
          <div>
            <Grid container spacing={1}>
              <Grid item>
                <Button
                  color="error"
                  variant="contained"
                  onClick={toggleRejectModal}
                >
                  <Box mr={1}>
                    <XLg size={10} />
                  </Box>
                  Reject
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="success"
                  variant="contained"
                  onClick={handleApprove}
                >
                  <Box mr={1}>
                    <Check size={16} />
                  </Box>
                  Approve
                </Button>
              </Grid>
            </Grid>
          </div>
        </Box>
        <Grid container flexWrap="wrap" spacing={4}>
          <LabelWithValue label="Impact Claim Level">
            {getImpactClaimLevel(claimSubmission)}
          </LabelWithValue>
          <LabelWithValue label="Company name">
            {claimSubmission.createdBy.name}
          </LabelWithValue>
          {(claimSubmission?.product ||
            claimSubmission?.createdBy ||
            claimSubmission.component) && (
            <LabelWithValue label="Related to">
              {getRelationTitle(claimSubmission)}
            </LabelWithValue>
          )}
          <LabelWithValue label="Submit date">
            {formatDateTime(claimSubmission.createdTimestamp)}
          </LabelWithValue>
          <LabelWithValue label="Last updated">
            {formatDateTime(claimSubmission.modifiedTimestamp)}
          </LabelWithValue>
          <LabelWithValue label="Attachments">
            {claimSubmission.attachmentCount}
          </LabelWithValue>
          <LabelWithValue label="Status">
            {claimSubmission.status}
          </LabelWithValue>
        </Grid>
        <Box mb={2} mt={4}>
          <Typography variant="h3">Proof documents</Typography>
        </Box>
        <Grid container direction="column" spacing={2}>
          {claimSubmission.attachments.edges.map(({ node: document }) => (
            <Grid item key={document.id}>
              <DocumentContainer document={document}>
                <Fragment>{document.title}</Fragment>
              </DocumentContainer>
              {claimSubmission.createdBy.id !== document.createdById && (
                <Box alignItems="center" display="flex" mt={1}>
                  <InfoOutline size={18} />
                  <Box mr={1} />
                  <Typography>
                    This document is owned by another company
                  </Typography>
                </Box>
              )}
            </Grid>
          ))}
        </Grid>
        <Box mb={2} mt={4}>
          <Typography variant="h3">History</Typography>
        </Box>
        <ClaimHistory history={impactClaimHistory} />
      </Container>
    </Fragment>
  );
};

export default SubmissionDetails;
