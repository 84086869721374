import { IMPACT_CATALOG_ITEM_FRAGMENT } from 'graphql/fragments';
import {
  IMPACT_CLAIM_FRAGMENT,
  IMPACT_CLAIM_LOG
} from 'graphql/fragments/impactClaims';
import gql from 'graphql-tag';

export const GET_IMPACT_CATALOG_ITEMS = gql`
  query GetImpactCatalogItems {
    impactCatalogItems {
      edges {
        node {
          ...impactCatalogItemValues
        }
      }
    }
  }
  ${IMPACT_CATALOG_ITEM_FRAGMENT}
`;

export const GET_SINGLE_CLAIM_SUBMISSION = gql`
  query GetClaim($id: UUID!, $lang: Language!) {
    impactClaim(id: $id, lang: $lang) {
      ...impactClaimValues
    }
    impactClaimHistory(id: $id) {
      edges {
        node {
          ...impactClaimLog
        }
      }
    }
  }
  ${IMPACT_CLAIM_FRAGMENT}
  ${IMPACT_CLAIM_LOG}
`;

export const GET_CLAIM_SUBMISSIONS = gql`
  query GetClaims {
    impactClaims {
      edges {
        node {
          id
          status
          createdTimestamp
          attachmentCount
          createdBy {
            id
            name
          }
          impactCatalogItem {
            category {
              title
              type
              id
            }
            title
          }
        }
      }
    }
  }
`;

export const GET_IMPACT_CATALOG_CATEGORIES = gql`
  query getImpactCatalogCategories {
    impactCatalogCategories {
      edges {
        node {
          id
          title {
            value
            language
          }
          type
        }
      }
    }
  }
`;
