import { gql } from '@apollo/client';

export const DOCUMENT_FRAGMENT = gql`
  fragment documentsValues on Document {
    id
    path
    url
    title
    createdTimestamp
    expiryDate
    issuedDate
    createdById
  }
`;
