import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TabContext, TabList } from '@mui/lab';
import {
  Alert,
  Avatar,
  Box,
  Grid,
  Link,
  Stack,
  styled,
  Tab,
  Tooltip,
  Typography
} from '@mui/material';
import copyTextToClipboard from 'utils/copyTextToClipboard';
import { formatDateTime } from 'utils/dates';
import { GoBackLink } from 'components/ImpactClaims/ActiveSubmission/styles';
import Loader from 'components/Loader/Loader';
import LabelWithValue from 'components/shared/LabelWithValue/LabelWithValue';
import { useSingleCompany } from '../hooks/useSingleCompany';
import CompanyAnalytics from './Analytics/CompanyAnalytics';
import ProductAnalytics from './Analytics/ProductAnalytics';
import CompanyAddress from './CompanyAddress/CompanyAddress';
import CompanyDataTabs from './CompanyDataTabs/CompanyDataTabs';
import CompanyFeatures from './CompanyFeatures/CompanyFeatures';
import CompanyPlan from './CompanyPlan/CompanyPlan';
import CompanyStatistics from './CompanyStatistics/CompanyStatistics';
import { StyledContainer, StyledGrid, StyledTabPanel } from './styles';

const IMAGE_SIZE = 50;

const StyledAvatar = styled(Avatar)`
  width: ${IMAGE_SIZE}px;
  height: ${IMAGE_SIZE}px;
  margin-right: 20px;
`;

enum PageTabs {
  COMPANY_ANALYTICS = 'COMPANY_ANALYTICS',
  DETAILS = 'DETAILS',
  PRODUCT_ANALYTICS = 'PRODUCT_ANALYTICS'
}

const ActiveCompanyPage: React.FC = () => {
  const { companyId } = useParams();
  const { company, error, impactClaimKpis, loading, productKpis, userKpis } =
    useSingleCompany(companyId || '');
  const [activeTab, setActiveTab] = useState<PageTabs>(PageTabs.DETAILS);
  const handleChange = (event: React.SyntheticEvent, newValue: PageTabs) => {
    setActiveTab(newValue);
  };
  const [copied, setCopied] = useState(false);

  const disableAnalytics: boolean = company?.products.length === 0;

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (company === undefined) {
    return (
      <Alert severity="warning">No company found with id {companyId}</Alert>
    );
  }

  return (
    <Box>
      <Box mb={3}>
        <GoBackLink to="/companies">Go back</GoBackLink>
      </Box>

      <StyledContainer paddingX={3} paddingY={2}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <StyledAvatar src={company.logo?.url} />
          <Box alignItems="center" display="flex">
            <Typography variant="h4">{company.name}</Typography>
            <Box ml={1} />
            <Typography variant="h4">- </Typography>
            <Tooltip
              leaveDelay={400}
              open={copied}
              title="Copied company ID to clipboard"
              onClose={() => setCopied(false)}
            >
              <Box
                ml={1}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setCopied(true);
                  copyTextToClipboard(company.id);
                }}
              >
                <Typography color="grey" variant="h6">
                  {company.id}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </Box>

        <Box mt={3}>
          <StyledGrid container spacing={3}>
            <LabelWithValue label="Website">
              <Link href={company.website} target="blank">
                {company.website}
              </Link>
            </LabelWithValue>
            <LabelWithValue label="Created On">
              {formatDateTime(company.createdTimestamp)}
            </LabelWithValue>
          </StyledGrid>
        </Box>
      </StyledContainer>

      <Box mt={3}>
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange}>
              <Tab label="Company Info" value={PageTabs.DETAILS} />
              <Tab
                disabled={disableAnalytics}
                label="Company Analytics"
                value={PageTabs.COMPANY_ANALYTICS}
              />
              <Tab
                disabled={disableAnalytics}
                label="Product Analytics"
                value={PageTabs.PRODUCT_ANALYTICS}
              />
            </TabList>
          </Box>

          <StyledTabPanel value={PageTabs.DETAILS}>
            <Grid container spacing={3}>
              <Grid item xs={9}>
                <Stack spacing={3}>
                  <CompanyAddress company={company} />

                  {company.planDetails && (
                    <CompanyPlan
                      companyId={company.id}
                      planDetails={company.planDetails}
                    />
                  )}

                  {company.planDetails?.features && (
                    <CompanyFeatures
                      companyId={company.id}
                      features={company.planDetails?.features}
                    />
                  )}

                  <StyledContainer>
                    <CompanyDataTabs company={company} />
                  </StyledContainer>
                </Stack>
              </Grid>

              <Grid item xs={3}>
                <CompanyStatistics
                  impactClaimKpis={impactClaimKpis}
                  productKpis={productKpis}
                  userKpis={userKpis}
                />
              </Grid>
            </Grid>
          </StyledTabPanel>

          {companyId && (
            <StyledTabPanel value={PageTabs.COMPANY_ANALYTICS}>
              <CompanyAnalytics companyId={companyId} />
            </StyledTabPanel>
          )}

          <StyledTabPanel value={PageTabs.PRODUCT_ANALYTICS}>
            <ProductAnalytics products={company.products} />
          </StyledTabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default ActiveCompanyPage;
