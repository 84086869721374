import { GraphQlConnection, ImpactClaimSubmission } from './types.types';

export type WidgetType = 'BUTTON' | 'MAP';

export enum AvailableLanguages {
  DUTCH = 'nl',
  ENGLISH = 'en',
  FRENCH = 'fr',
  GERMAN = 'de',
  SPANISH = 'es'
}

export enum ProductStatus {
  ARCHIVED = 'ARCHIVED',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  ERROR = 'ERROR',
  PUBLISHED = 'PUBLISHED'
}

export enum PlanType {
  FREEMIUM = 'FREEMIUM',
  INACTIVE = 'INACTIVE',
  INTERNAL = 'INTERNAL',
  PAYING = 'PAYING',
  TRIAL = 'TRIAL'
}

export interface CompanyTypography {
  body: string;
  header: string;
}

export enum CompanyFeature {
  CERTIFICATES = 'CERTIFICATES',
  COMPLIANCE = 'COMPLIANCE',
  FEEDBACK = 'FEEDBACK',
  PAYMENTS = 'PAYMENTS',
  VERIFIED_PAYMENTS = 'VERIFIED_PAYMENTS',
  WHITE_LABELING = 'WHITE_LABELING'
}

export type CompanyFeatureType = `${CompanyFeature}`;

export enum CompanyStatus {
  INVITED = 'INVITED',
  NOT_INVITED = 'NOT_INVITED',
  REGISTERED = 'REGISTERED'
}

export interface CompanyPalette {
  primary: string;
  secondary: string;
}

export interface CompanyDesign {
  buttonBorderRadius: number;
  defaultBorderRadius: number;
  palette: CompanyPalette;
  typography: CompanyTypography;
}

export interface Address {
  city: string;
  country: string;
  street: string;
  streetNumber: string;
  zipCode: string;
}

export interface CompanyUser {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  latestActivityTimestamp: string;
}

export interface IntegrationSettings {
  widgetType: WidgetType;
}

export interface Cropping {
  height: number;
  width: number;
  x: number;
  y: number;
}

export interface ImageVariant {
  attribution?: string;
  createdTimestamp: string;
  crop?: Cropping;
  height: number;
  id: string;
  imageId: string;
  interface?: string;
  isOffensive: boolean;
  labels: string[];
  name: string;
  path: string;
  size: number;
  url: string;
  width: number;
}

export interface CustomerPlan {
  expirationDate?: string | null;
  expired: boolean;
  features: CompanyFeatureType[];
  name: string;
  planType: PlanType;
  productLimit: number;
  upgradeRequested: boolean;
  userLimit: number;
}

export type Company = {
  address?: Address;
  apiKey: string;
  createdTimestamp: string;
  dataValid: boolean;
  design?: CompanyDesign;
  gaTrackingId: string;
  id: string;
  integrationSettings?: IntegrationSettings;
  invitedByCompany?: { id: string; name: string };
  logo?: ImageVariant;
  name: string;
  onlineShop?: string;
  planDetails?: CustomerPlan;
  status: CompanyStatus;
  website: string;
};

export interface ProductKpis {
  drafts: number;
  published: number;
  total: number;
}

export interface ImpactClaimKpis {
  approved: number;
  requested: number;
  total: number;
}

export interface UserKpis {
  notRegistered: number;
  registered: number;
  total: number;
}

export interface CertificationKpis {
  total: number;
}

export interface ProductKpiCollection {
  kpis: ProductKpis;
}
export interface ImpactClaimKpiCollection {
  kpis: ImpactClaimKpis;
}

export interface UserKpiCollection {
  kpis: UserKpis;
}

export interface CertificationKpiCollection {
  kpis: CertificationKpis;
}

export interface Product {
  id: string;
  imageUrl?: string | undefined;
  languageConfig?: {
    default: AvailableLanguages;
    enabled: AvailableLanguages[];
  };
  publicUrl?: string;
  publishedLanguages: AvailableLanguages[];
  slug: string;
  status: ProductStatus;
  title: string;
}

export interface CompanyFetchResult {
  companies: GraphQlConnection<Company>;
}

export interface CompanyCertification {
  description: string;
  id: string;
  title: string;
}

export interface ExtendedCompanyFetchResult extends Company {
  impactClaims: GraphQlConnection<ImpactClaimSubmission> &
    ImpactClaimKpiCollection;
  products: GraphQlConnection<Product> & ProductKpiCollection;
  users: GraphQlConnection<CompanyUser> & UserKpiCollection;
}

export interface SingleCompanyFetchResult {
  company: ExtendedCompanyFetchResult;
}

export interface ExtendedCompany extends Company {
  impactClaims: ImpactClaimSubmission[];
  products: Product[];
  users: CompanyUser[];
}

export interface FeaturesFetchResult {
  companyFeatures: CompanyFeatureType[];
}
