import React, { Fragment, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Avatar,
  Box,
  MenuItem,
  Select,
  TableCell,
  Typography
} from '@mui/material';
import get from 'lodash/get';
import styled from 'styled-components';
import { Company, CompanyStatus, PlanType } from 'types/companies.types';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault
} from 'use-query-params';
import { formatDateTime } from 'utils/dates';
import Loader from 'components/Loader/Loader';
import Table, { HeadCell } from 'components/Table/Table';
import { useCompanies } from '../hooks/useCompanies';
import { SearchField } from './styles';

const StyledSelect = styled(Select)`
  margin-left: ${({ theme }) => theme.spacing(1)};

  .MuiSelect-select {
    color: black;
    padding: ${({ theme }) => theme.spacing(1, 1.5)};
  }
`;

const headCells: HeadCell<Company>[] = [
  {
    id: 'logo',
    label: ''
  },
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'website',
    label: 'Website'
  },
  {
    id: 'planDetails.expirationDate',
    label: 'Expiration Date'
  },
  {
    id: 'invitedByCompany.name',
    label: 'Invited by'
  },
  {
    id: 'status',
    label: 'Status'
  },
  {
    id: 'createdTimestamp',
    label: 'Created On'
  }
];

const ICON_SIZE = 35;

const CompaniesPage: React.FC = () => {
  const [query, setQuery] = useQueryParams({
    search: withDefault(StringParam, ''),
    expirationState: withDefault(StringParam, 'ALL'),
    status: withDefault(StringParam, CompanyStatus.REGISTERED),
    planType: withDefault(StringParam, PlanType.PAYING),
    pageSize: withDefault(NumberParam, 20),
    page: withDefault(NumberParam, 0)
  });
  const { expirationState, page, pageSize, planType, search, status } = query;

  const { companies, error, loading } = useCompanies();
  const navigate = useNavigate();

  const filteredCompanies = useMemo(
    () =>
      companies?.filter(
        company =>
          company.name.toLowerCase().includes(search.toLowerCase()) &&
          (planType === 'ALL' ||
            (planType !== 'ALL' &&
              company.planDetails?.planType === planType)) &&
          (expirationState === 'ALL' ||
            (expirationState === 'ACTIVE' && !company.planDetails?.expired) ||
            (expirationState === 'EXPIRED' && company.planDetails?.expired)) &&
          (status === 'ALL' || status === company.status)
      ),
    [planType, search, companies, status, expirationState]
  );

  const handleNavigateToCompany = useCallback(
    (companyId: string) => {
      navigate(`/companies/${companyId}`);
    },
    [navigate]
  );

  const filteredHeadCells = headCells;

  if (loading && companies.length === 0) {
    return <Loader />;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <div>
      <SearchField
        id="outlined-basic"
        placeholder="Search..."
        size="small"
        value={search}
        variant="outlined"
        onChange={event => {
          setQuery({ search: event.target.value, page: 0 });
        }}
      />
      <StyledSelect
        value={planType}
        onChange={event => {
          setQuery({ planType: event.target.value as PlanType, page: 0 });
        }}
      >
        <MenuItem key="ALL" value="ALL">
          ALL TYPES
        </MenuItem>
        {Object.keys(PlanType).map(planType => (
          <MenuItem key={planType} value={planType}>
            {planType}
          </MenuItem>
        ))}
      </StyledSelect>
      <StyledSelect
        value={expirationState}
        onChange={event => {
          setQuery({ expirationState: event.target.value as string, page: 0 });
        }}
      >
        <MenuItem value="ALL">ALL STATES</MenuItem>
        <MenuItem value="ACTIVE">ACTIVE</MenuItem>
        <MenuItem value="EXPIRED">EXPIRED</MenuItem>
      </StyledSelect>
      <StyledSelect
        value={status}
        onChange={event => {
          setQuery({ status: event.target.value as CompanyStatus, page: 0 });
        }}
      >
        <MenuItem value="ALL"> ALL STATUS</MenuItem>
        {Object.keys(CompanyStatus).map(planType => (
          <MenuItem key={planType} value={planType}>
            {planType}
          </MenuItem>
        ))}
      </StyledSelect>

      <Box mb={2}>
        <Typography color="GrayText" fontWeight={500} variant="body2">
          {companies.length} total registered companies,{' '}
          {filteredCompanies.length} companies available with applied filters
        </Typography>
      </Box>

      <Table<Company>
        defaultRowsPerPage={20}
        defaultSorting="title"
        headCells={filteredHeadCells}
        page={page}
        pageSize={pageSize}
        rows={filteredCompanies || []}
        onPageChange={page => setQuery({ page })}
        onPageSizeChange={pageSize => setQuery({ pageSize })}
        onRowClick={handleNavigateToCompany}
      >
        {row => (
          <Fragment>
            {headCells.map(({ id }) => {
              if (id === 'logo') {
                return (
                  <TableCell key={id} size="small" sx={{ width: '60px' }}>
                    <Avatar
                      src={row.logo?.url}
                      sx={{ width: ICON_SIZE, height: ICON_SIZE }}
                    />
                  </TableCell>
                );
              }

              if (id === 'createdTimestamp') {
                return (
                  <TableCell key={id} size="small">
                    <div>{formatDateTime(get(row, id))}</div>
                  </TableCell>
                );
              }

              if (id === 'name') {
                const isExpired = !!get(row, 'planDetails.expired');
                return (
                  <TableCell key={id} size="small">
                    <div>{get(row, id)}</div>
                    {row.planDetails && (
                      <Box display="flex" gap={1}>
                        <Typography color="grey" variant="caption">
                          {row.planDetails.planType}
                        </Typography>
                        /
                        <Typography
                          color={isExpired ? 'orange' : 'green'}
                          variant="caption"
                        >
                          {isExpired ? 'Expired' : 'Active'}
                        </Typography>
                      </Box>
                    )}
                  </TableCell>
                );
              }

              if (id === 'invitedByCompany.name') {
                return (
                  <TableCell key={id} size="small">
                    <div>{get(row, id)}</div>
                  </TableCell>
                );
              }

              if (id === 'status') {
                return (
                  <TableCell key={id} size="small">
                    <div>{get(row, id)}</div>
                  </TableCell>
                );
              }

              return <TableCell key={id}>{get(row, id)}</TableCell>;
            })}
          </Fragment>
        )}
      </Table>
    </div>
  );
};

export default CompaniesPage;
